@import "~bootstrap/dist/css/bootstrap.css";
@import '~ngx-toastr/toastr';
@import '~material-icons/iconfont/material-icons.css';

/* You can add global styles to this file, and also import other style files */
body {
    margin: 0px !important;
    font-family: 'Poppins', sans-serif !important;
    font-size: 16px;
    background: white;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    font-family: 'Poppins', sans-serif !important;
}

.navbar {
    position: fixed;
    /* width: 94%; */
    z-index: 9;
    /* height: 105px; */
    /* background: #ff5c7b !important; */
    background: transparent !important;
}

.newnavbar {
    width: 94%;
}

.navbar-brand,
.nav-link {
    color: #fff !important;
}

/* .nav-link:hover {
    color: #000 !important;
} */

.navbar-nav>.active>a {
    color: #ff5c7b;
}

.newnavbar .navbar-brand,
.newnavbar .nav-link {
    color: rgba(0, 0, 0, 0.6) !important;
    padding: 15px 5px;
}

.nav-flexend {
    width: 100%;
    justify-content: flex-end;
}

.five-circ {
    display: flex;
    justify-content: center;
    position: relative;
    top: 60%;
}

.mt-18 {
    margin-top: 18rem;
}

/* .home-txtmid {
    position: absolute;
    text-align: center;
    top: -225px;
    width: 50%;
    color: #fff;
} */
.prel-l10 {
    position: relative;
    /* right: 5px; */
}

.fsize23 {
    font-size: 23px !important;
}

.home-txtmid h1 {
    font-size: 54px;
    font-family: 'Source Sans Pro', sans-serif !important;
}

.home-txtmid p {
    font-size: 15px;
    opacity: 0.85;
    color: #f9f9f9;
}

.why-choose div {
    box-shadow: 0px 0px 10px #ddd;
    border-radius: 10px;
    /* padding: 20px; */
    text-align: center;
}

.why-choose:nth-child(2) div {
    /* background: #FF5C7B; */
    margin-top: 2rem;
}

.why-choose div:hover,
.btn-snape-red {
    background: #FF5C7B;
    color: #fff;
}

.btn-cancel-grey {
    background: #14171a;
    opacity: 0.2;
    color: #fff;
}

.btn-cancel-grey:hover {
    background: #14171a;
    opacity: 0.6;
    color: #fff;
}

.snape-red,
.fa-snape-red {
    color: #FF5C7B;
}

.btn-snape-redOut {
    background: transparent;
    color: #FF5C7B;
    border: 1px solid #FF5C7B;
    border-radius: 5px;
}

.btn-snape-redOut:hover {
    background: #FF5C7B;
    color: #fff;
}

.cursor-pointer {
    cursor: pointer !important;
}

.cart-summary {
    border: 1px solid #ddd;
    min-height: 490px;
    padding: 15px;
    border-radius: 10px;
    height: auto;
}

.mfooterBtn button {
    width: 135px;
    height: 36px;
}

.mfooterContinue button {
    width: 100%;
    height: 36px;
}

.footer-classic {
    position: relative;
}

.fSize14px {
    font-size: 14px;
}

.fsize15 {
    font-size: 15px;
}

.fSize18px {
    font-size: 18px;
}

.fsize20 {
    font-size: 20px;
}

.bRad10px {
    border-radius: 10px;
}

.btn-snape-bak {
    background: #FF5C7B;
    color: #fff;
}

.login-bgpic {
    background-image: url('./assets/loginimg.png');
    object-fit: cover;
    height: 100vh;
    background-size: cover;
}

.logintxt {
    display: flex;
    align-items: center;
    justify-content: center;
}

.auth-ui {
    width: 75%;
}

.forgetpass {
    color: #000 !important;
    font-size: 12px;
}

.w100 {
    width: 100%;
}

.w95 {
    width: 95%;
}

::placeholder {
    color: #ccc;
    font-size: 12px;
}

.sidenavbar {
    height: 100vh;
    padding: unset !important;
    position: absolute;
}

.sidenavbar .navbar-nav {
    width: 100%;
    background: #fff;
    height: 550px;
    /* box-shadow: 0px 0px 1px #aaa; */
}

.height550 {
    max-height: 550px;
    min-height: 550px;
    height: auto;
    overflow-y: auto;
}

.sidenavbar ul {
    position: absolute;
    top: 2rem;
    padding-left: 10px;
}

.sidenavbar .navbar-brand {
    color: hsla(210, 13%, 9%, 0.603) !important;
    margin: 10px auto;
    width: 100%;
}

.sidenavbar .navbar-brand {
    color: black !important;
    border-right: 2px solid #FF5C7B;
}

.pro-Bordr {
    /* border-left: 1px solid #ddd; */
}

.bRadius5px {
    border-radius: 5px;
}

.deactivate {
    position: absolute;
    bottom: 0;
    right: 20px;
    color: #aaa;
}

button.close {
    padding: 0px 5px;
    color: #FF5C7B;
    opacity: 0.5;
    outline: none;
}

button.close:hover {
    opacity: 1;
    color: #FF5C7B;
}

.text-grey {
    color: #000;
    opacity: 0.6;
}

.fontSize12px {
    font-size: 12px;
}

.fw600 {
    font-weight: 600;
}

.btn {
    /* font-size: 13px !important; */
}

.fa-trash {
    color: #000;
    opacity: 0.4;
}

.form-control {
    font-size: 13px;
    height: 45px;
}

.mt-4rem {
    margin-top: 4rem;
}

.mb-4rem {
    margin-bottom: 4rem;
}

.product-image {
    position: relative;
    /* width: 300px;
    height: 300px;
    overflow: hidden; */
}

.prodDiv img {
    height: 150px;
    width: auto;
    min-height: 150px;
    border-radius: 5px;
    object-fit: fill;
}

.prodDiv {
    /* background: #fff;
    margin: 10px;
    padding: 15px 0px; */
    border-radius: 5px;
}

.prodDiv:hover {
    /* padding: 15px 0px; */
}

.image {
    position: relative;
}

.canvas-container {
    height: 280px !important;
    width: 280px !important;
    z-index: 9;
}

.image:after {
    content: "";
    color: #fff;
    position: absolute;
    width: 90%;
    height: 150px;
    top: 0;
    left: 13px;
    background: rgba(0, 0, 0, 0.7);
    opacity: 0;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    z-index: 1;
    border-radius: 5px;
    padding: 0;
}

.image:before {
    content: "Order Now";
    color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    margin: auto;
    z-index: 2;
    background-color: #ff5c7b;
    height: 45px;
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
}

.image:hover:after {
    opacity: 1;
}

.image:hover:before {
    opacity: 1;
}

.btn210 {
    width: 210px;
    height: 45px;
}

.ColorBOp,
.gfaIcon {
    color: #000;
    opacity: 0.5 !important;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.p-t-25 {
    padding-top: 25px;
}

@media only screen and (max-width: 767px) {
    .mt-18 {
        margin-top: unset;
    }

    .sidenavbar {
        height: 400px;
        position: relative;
    }

    .navbar-collapse {
        background: #fff;
        color: #000;
    }

    .navbar-light .navbar-nav .show>.nav-link,
    .navbar-light .navbar-nav .active>.nav-link,
    .navbar-light .navbar-nav .nav-link.show,
    .navbar-light .navbar-nav .nav-link.active,
    .navbar-light .navbar-nav .nav-link {
        color: #000 !important;
    }

    .choose-cookie,
    .cus-cookie,
    .del-cookie,
    .cus-happy {
        left: unset !important;
        right: unset !important;
        top: unset !important;
    }

    .wework,
    .faqlist {
        width: 100% !important;
    }

    .home-txtmid h1 {
        font-size: 2rem !important;
    }

    .home-txtmid p {
        display: none;
    }

    .canvaDiv {
        left: unset !important;
    }

    .borlr {
        border: none !important;
    }

    .tcenMob {
        text-align: center !important;
    }
}



/* testing */
@media only screen and (min-width: 767px) and (max-width: 991px) {
    .mt-18 {
        margin-top: unset;
    }

    .sidenavbar {
        height: 400px;
        position: relative;
    }

    .navbar-collapse {
        background: #fff;
        color: #000;
    }

    .navbar-light .navbar-nav .show>.nav-link,
    .navbar-light .navbar-nav .active>.nav-link,
    .navbar-light .navbar-nav .nav-link.show,
    .navbar-light .navbar-nav .nav-link.active,
    .navbar-light .navbar-nav .nav-link {
        color: #000 !important;
    }

    .choose-cookie,
    .cus-cookie,
    .del-cookie,
    .cus-happy {
        left: unset !important;
        right: unset !important;
        top: unset !important;
    }

    .wework,
    .faqlist {
        width: 100% !important;
    }

    .home-txtmid h1 {
        font-size: 2rem !important;
    }

    .home-txtmid p {
        display: none;
    }

    /* .canvaDiv{
        left: unset !important;
    } */
}

/* testing */
@media (min-width: 576px) {
    .container {
        max-width: 576px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 768px;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 992px;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1200px;
    }
}

@media (min-width: 1280px) {
    .container {
        max-width: 1280px;
    }

    /* .canvaDiv {
        left: 17vw !important;
        top: 28vh !important;
    } */
}

@media (min-width: 1366px) {
    .container {
        max-width: 1366px;
    }

    /* .canvaDiv{
        left: 15.70vw !important;
        top: 27vh !important;
    } */
}

@media (min-width: 1440px) {
    .container {
        max-width: 1440px;
    }

    /* .canvaDiv {
        left: 18.5vw !important;
        top: 30vh !important;
    } */
}

/* edit-profile-image-croper-modal */
/* .cropper { 
    height: 260px !important; 
  }  */
.btn:hover {
    color: #ffffff;
    text-decoration: none;
}

.font-size-18 {
    font-size: 18px;
}

.text-align {
    text-align: center;
}

.secondary-font-color {
    color: #707070;
}

.text-danger {
    font-size: 11px;
}

/* this code for primeng time picker start */
.p-calendar {
    width: 100%;
}

.p-calendar-w-btn .p-datepicker-trigger {
    height: 42px !important;
    background-color: #ffdae1;
    color: #f3002e;
    border: 1px solid #ffdae1;
}

/* .p-calendar-w-btn .p-inputtext {
    border: 1px solid red;
}

*/
/* this code for primeng time picker end */
/* this code for edge password show hide focus */
input::-ms-reveal,
input::-ms-clear {
  display: none;
}
/* this code for edge password show hide focus */
/* https://stackoverflow.com/questions/61449079/how-to-hide-the-eye-from-a-password-input-in-ms-edge-and-ie */